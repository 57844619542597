
import React from 'react';

import CountUpAnimation from '../../helper/count_up_animation';
import { formatNumber } from '../../helper/number_formatter';

import styles from './styles.module.scss'
import { useIsBoiWebsite } from "../Context/WebsiteType";

interface IStatNumber {
  number: number;
  text: string;
  animate: boolean;
  countUp?: boolean;
  format?: boolean;
  unit?: string;
  speed?: number;
  white?: boolean;
  className?: string;
}

export default function StatNumber({ 
  number, text, animate, format, countUp = true, 
  unit, speed = 50, className
} : IStatNumber) {
  let countNumber: number | string = number
  const isBoiWebsite = useIsBoiWebsite();

  if (countUp) {
    countNumber = CountUpAnimation(number, animate, speed);
  }

  if (format) {
    countNumber = formatNumber(countNumber);
  }

  return (
    <div className={`${isBoiWebsite ? styles.numberBoi : styles.number} ${className}`}>
      <h2>{ countNumber }{unit}</h2>
      <p>{ text }</p>
    </div>
  )
};
